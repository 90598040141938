export const NEWS_TITLES = ({
  isTrending,
  language,
}: {
  isTrending?: boolean;
  language: string;
}) => {
  return `
  query NewsTitles($limit: Int, $sort: String) {
    allNews(
      where: { 
        status: { equals: published },
        ${isTrending !== undefined ? `isTrending: { equals: ${isTrending} }` : ""}
        ${language !== undefined ? `language: { equals: ${language} }` : ""}
      },
      limit: $limit,
      sort: $sort
    ) {
      docs {
        id
        title
        slug
        publishedAt
        isTrending
        trendingPosition
        categoryId {
          slug
        }
        subCategoryId {
          slug
        }
        isLiveNews
        isInPrint
      }
    }
  }
`;
};

export const NEWS = ({
  categoryId,
  subCategoryId,
  isTrending,
  language,
}: {
  categoryId?: string;
  subCategoryId?: string;
  isTrending?: boolean;
  language?: string;
}): string => {
  return `
  query News($limit: Int, $sort: String, $page: Int) {
    allNews(
      where: {
        status: { equals: published },
        ${categoryId ? `categoryId: { equals: "${categoryId}" }` : ""}
        ${subCategoryId ? `subCategoryId: { in: ["${subCategoryId}"] }` : ""}
        ${isTrending !== undefined ? `isTrending: { equals: ${isTrending} }` : ""}
        ${language !== undefined ? `language: { equals: ${language} }` : ""}
      },
      limit: $limit,
      sort: $sort,
      page: $page,
    ) {
      docs {
        id
        slug
        title
        summary
        isTrending
        image {
          alt
          url
          filename
          height
          width
          sizes {
            md {
              url
              filename
            }
            sd {
              url
              filename
            }
            ld {
              url
              filename
            }
          }
        }
        categoryId {
          name
          slug
        }
        subCategoryId {
          name
          slug
        }
        publishedAt
        focusPosition
        isInPrint
        isLiveNews
      }
      totalDocs
    }
  }`;
};

export const NEWS_BY_SLUG = (isLivePreview?: string): string => {
  return `
  query getNewsBySlug($slug: String!) {
    allNews(where: { ${
      isLivePreview ? "" : "status: { equals: published },"
    } slug: { equals: $slug } }) {
      docs {
        title
        slug
        summary
        image {
          alt
          url
          filename
          sizes {
            md {
              url
              filename
            }
            sd {
              url
              filename
            }
            ld {
              url
              filename
            }
          }
        }
        categoryId {
          name
          slug
          id
        }
        subCategoryId {
          name
          slug
        }
        tagIds {
          name
        }
        isTrending
        trendingPosition
        status
        publishedAt
        likes
        views
        comments {
          comment
          userId {
            firstName
            lastName
          }
          createdAt
        }
        meta {
          title
          description
          keywords
          image {
            url
          }
        }
      }
    }
  }`;
};

export const NEWS_WITH_BYLINE = ({
  categoryId,
  subCategoryId,
  isTrending,
  language,
}: {
  categoryId?: string;
  subCategoryId?: string;
  isTrending?: boolean;
  language?: string;
}): string => {
  return `
  query News($limit: Int, $sort: String) {
    allNews(
      where: {
        status: { equals: published },
        ${categoryId ? `categoryId: { equals: "${categoryId}" }` : ""}
        ${subCategoryId ? `subCategoryId: { equals: "${subCategoryId}" }` : ""}
        ${isTrending !== undefined ? `isTrending: { equals: ${isTrending} }` : ""}
        ${language !== undefined ? `language: { equals: ${language} }` : ""}
      },
      limit: $limit,
      sort: $sort
    ) {
      docs {
        id
        slug
        title
        summary
        isTrending
        image {
          alt
          url
          filename
          height
          width
          sizes {
            md {
              url
              filename
            }
            sd {
              url
              filename
            }
            ld {
              url
              filename
            }
          }
        }
        categoryId {
          name
          slug
        }
        subCategoryId {
          name
          slug
        }
        publishedAt
        focusPosition
        byline{
          image{
              filename
          }
          name
        }
        isInPrint
        isLiveNews
      }
    }
  }`;
};

export const QUERY_TOTAL_NEWS_COUNT = `
  query getNewsCount {
    allNews(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;
