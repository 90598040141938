import envConfig from "@/config/envConfig";
import { getListQuery } from "@/graphql";
import { objToString } from "@/graphql/helper";
import { NEWS, NEWS_TITLES, NEWS_WITH_BYLINE, QUERY_TOTAL_NEWS_COUNT } from "@/graphql/news";
import { queryRecentNews } from "@/graphql/query";
import {
  ArticleBookmarkType,
  ArticleCommentType,
  ArticleListType,
  ArticleType,
} from "@/types/article";
import { notFound } from "next/navigation";

export const fetchNews = async ({
  categoryId,
  subCategoryId,
  isTrending,
  limit = 10,
  sort = "-publishedAt",
  cache = "no-cache",
  withByline = false,
  language,
}: {
  categoryId?: string;
  subCategoryId?: string;
  isTrending?: boolean;
  limit?: number;
  sort?: "publishedAt" | "-publishedAt" | "-trendingPosition" | "trendingPosition" | "-views";
  cache?: RequestCache;
  withByline?: boolean;
  language?: string;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: withByline
          ? NEWS_WITH_BYLINE({ categoryId, subCategoryId, isTrending, language })
          : NEWS({ categoryId, subCategoryId, isTrending, language }),
        variables: {
          limit,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.allNews?.docs || []) as ArticleListType[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchPaginatedNews = async ({
  categoryId,
  subCategoryId,
  isTrending,
  limit = 10,
  sort = "-publishedAt",
  cache = "no-cache",
  page,
}: {
  categoryId?: string;
  subCategoryId?: string;
  isTrending?: boolean;
  limit?: number;
  sort?: "publishedAt" | "-publishedAt" | "-trendingPosition" | "-views";
  cache?: RequestCache;
  page: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: NEWS({ categoryId, subCategoryId, isTrending }),
        variables: {
          limit,
          sort,
          page,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return { data: respData?.data?.allNews };
  } catch (err) {
    // return [];
  }
};

export const fetchNewsTitles = async ({
  limit = 10,
  sort = "-publishedAt",
  isTrending,
  cache = "no-cache",
  language,
}: {
  limit?: number;
  sort?: "publishedAt" | "-publishedAt" | "-trendingPosition" | "-views";
  isTrending?: boolean;
  cache?: RequestCache;
  language: string;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: NEWS_TITLES({ isTrending, language }),
        variables: {
          limit,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.allNews?.docs || []) as ArticleListType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchSingleDetailNews = async ({
  slug,
  cache = "no-cache",
  token,
}: {
  slug: string;
  cache?: RequestCache;
  token?: string;
}) => {
  try {
    const response = await fetch(envConfig.publicServerUrl + `/api/v1/news/slug/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cache,
    });

    if (!response.ok) {
      throw Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();

    return (respData?.data || null) as ArticleType;
  } catch (err) {
    return notFound();
  }
};

export const fetchNewsByTag = async ({
  tagSlug,
  limit,
  page,
}: {
  tagSlug: string;
  limit: number;
  page: number;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/tag/${tagSlug}?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchNewsBySubCategory = async ({
  subCategorySlug,
  limit,
  page,
  cache = "no-cache",
}: {
  subCategorySlug: string;
  limit: number;
  page: number;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/sub-category/${subCategorySlug}?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache,
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchNewsByCategory = async ({
  categoryId,
  subCategoryId,
  isTrending,
  limit,
  cache = "no-cache",
  sort,
}: {
  categoryId: string;
  subCategoryId?: string;
  isTrending?: boolean;
  limit: number;
  cache?: RequestCache;
  sort?: string;
}): Promise<ArticleListType[]> => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: NEWS({ categoryId, subCategoryId, isTrending }),
        variables: {
          limit,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.allNews?.docs || []) as ArticleListType[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchConditionalNews = async (
  where = { status: { not_equals: "draft" } },
  limit: number,
  sort: string,
): Promise<ArticleListType[]> => {
  return await getListQuery(queryRecentNews({ limit, sort, where: objToString(where) }))
    .then((response: any) => {
      return response?.data?.allNews?.docs;
    })
    .catch(error => {
      console.log(error);
    });
};

export const fetchConditionalNewsAPI = async ({
  block,
  pageSlug,
  limit,
}: {
  block: any;
  pageSlug: string;
  limit?: number;
}) => {
  limit && (block.limit = limit);
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/blocks/news`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        block,
        pageSlug,
        itemIndex: block?.id,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data;
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchTrendingNewsAPI = async ({ language }: { language: string }) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/blocks/trending`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        language,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();

    return data?.data;
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const PostRecommendedNews = async ({ newsId }: { newsId: string }) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/recommended`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newsId }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchRecommendedNews = async ({
  limit,
  cache = "no-cache",
  language,
}: {
  limit: number;
  cache?: RequestCache;
  language?: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/recommended?limit=${limit}&lang=${language}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache,
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data || []) as ArticleType[];
  } catch (err) {
    console.error("Error:", err);
    return [] as ArticleType[];
  }
};

export const fetchRelatedNews = async ({
  slug,
  limit,
  cache = "no-cache",
}: {
  slug: string;
  limit: number;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/related?slug=${slug}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache,
      },
    );

    if (!response.ok) {
      throw Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data || []) as ArticleType[];
  } catch (err) {
    console.error("Error:", err);
    return [] as ArticleType[];
  }
};

export const postNewsLike = async ({ newsId, isLiked }: { newsId: string; isLiked: boolean }) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newsId,
        isLiked,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const postNewsBookmark = async ({
  newsId,
  isBookmarked,
}: {
  newsId: string;
  isBookmarked: boolean;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/bookmark`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newsId,
        isBookmarked,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const fetchUserBookmarks = async () => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/user-bookmark`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { bookmarks: ArticleBookmarkType[] };
  } catch (err) {
    throw err;
  }
};

export const fetchNewsActivityStatus = async ({ newsId }: { newsId: string }) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/activity-status/${newsId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { isLiked: boolean; likesCount: number; isBookmarked: boolean };
  } catch (err) {
    throw err;
  }
};

export const postNewsComment = async ({
  newsId,
  comment,
  parentComment,
}: {
  newsId: string;
  comment: string;
  parentComment?: string;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/users/news-comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        newsId,
        comment,
        ...(parentComment && { parentComment }),
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { comment: ArticleCommentType };
  } catch (err) {
    throw err;
  }
};

export const fetchNewsComments = async ({
  newsId,
  limit = 10,
  page = 1,
  sortBy = "latest",
}: {
  newsId: string;
  limit?: number;
  page?: number;
  sortBy?: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/users/news-comments/${newsId}?limit=${limit}&page=${page}&sortBy=${sortBy}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { totalDocs: number; comments: ArticleCommentType[] };
  } catch (err) {
    throw err;
  }
};

export const fetchNewsByIds = async ({ newsIds }: { newsIds: string[] }) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/by-ids`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newsIds }),
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData?.newsList as ArticleListType[];
  } catch (err) {
    throw err;
  }
};

export const fetchTotalNewsCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_NEWS_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.allNews?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllNewsSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/slugs-list?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData || []) as ArticleListType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchNewsById = async (newsID: string) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/news/newsid/${newsID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData?.news as ArticleListType;
  } catch (err) {
    throw err;
  }
};

// lates news list page -> fetch all latest news with pagination.
export const fetchLatestNews = async ({ limit, page }: { limit: number; page: number }) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/news/latest?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchingLatestNews:", err);
    return err;
  }
};
